import googleLogo from "./google-logo.svg";
import { Form } from "@remix-run/react";

export function LoginForm() {
  return (
    <>
      <Google />
      <Apple />
    </>
  );
}

function Google() {
  return (
    <Form className="mb-1" action="/auth/login/google">
      <button
        type="submit"
        className="px-4 py-2 border flex gap-2 border-slate-200 dark:border-slate-700 rounded text-slate-700 dark:text-slate-200 hover:border-slate-400 dark:hover:border-slate-500 hover:text-slate-900 dark:hover:text-slate-300 hover:shadow transition duration-150 bg-white dark:bg-black items-center font-medium"
      >
        <img
          src={googleLogo}
          height={20}
          width={20}
          className="flex-shrink"
          style={{ maxWidth: "unset" }}
          alt=""
        />
        <span>Sign in with Google</span>
      </button>
    </Form>
  );
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function Apple() {
  return (
    <Form action="/auth/login/apple">
      <button
        type="submit"
        className="px-4 py-2 border flex gap-2 rounded hover:shadow transition duration-150 bg-black dark:bg-white text-white dark:text-black items-center font-medium"
      >
        <span></span>
        <span>Sign in with Apple</span>
      </button>
    </Form>
  );
}

import { useHydrated } from "remix-utils/use-hydrated";
import { Link, NavLink, useLocation } from "@remix-run/react";
import { navClass, NavigationLinks } from "./navigation";
import { LoginForm } from "./login";

const initialsRegex = new RegExp(/(\p{L}{1})\p{L}+/u, "gu");

function initialize(name: string) {
  const initials = [...name.matchAll(initialsRegex)] || [];
  return (
    (initials.shift()?.[1] || "") + (initials.pop()?.[1] || "")
  ).toUpperCase();
}

function Header({
  user,
}: {
  user: { email: string; name?: string; picture?: string } | null;
}) {
  return (
    <header className="border-b-2 mb-4">
      <div className="flex items-center justify-between py-4 px-6">
        <div className="flex items-center justify-between flex-wrap gap-y-2">
          <div className="flex items-center flex-shrink-0 mr-6">
            <Link to="/" className="font-semibold text-xl tracking-tight">
              Taos Trail Runners
            </Link>
          </div>
          <nav className="flex-grow flex-wrap gap-x-4 flex items-center">
            <NavigationLinks gap={4} center={false} />
          </nav>
        </div>
        <div className="relative flex-shrink-0 ml-4 self-baseline">
          <label
            htmlFor="user-menu"
            aria-haspopup="dialog"
            title="Open User Menu"
          >
            {user ? <Badge {...user} /> : "🏃"}
          </label>
          <input
            type="checkbox"
            name="user-menu"
            id="user-menu"
            className="hidden user-menu-checkbox"
          />
          <div
            id="user-menu-content"
            className="border rounded p-2 bg-slate-100 dark:bg-slate-800 absolute right-0 top-0 text-nowrap"
            role="dialog"
            aria-label="User Menu"
            style={{
              marginRight: "calc(-0.5rem - 1px)",
              marginTop: "calc(-0.5rem - 1px)",
            }}
          >
            <label
              htmlFor="user-menu"
              className="block text-right mb-2"
              title="Close User Menu"
            >
              {user ? <Badge {...user} /> : "🏃‍➡️"}
            </label>
            {user ? (
              <>
                <NavLink to="/perks" className={navClass}>
                  ✨ Perks
                </NavLink>
                <Link to="/auth/logout">Sign Out</Link>
              </>
            ) : (
              <LoginForm />
            )}
          </div>
        </div>
      </div>
    </header>
  );
}

function Footer() {
  const location = useLocation();
  const isHydrated = useHydrated();
  const url = isHydrated
    ? `${location.pathname}${location.search ? `?${location.search}` : ""}${location.hash ? `#${location.hash}` : ""}`
    : `${location.pathname}${location.search ? `?${location.search}` : ""}`;

  return (
    <footer className="mt-auto">
      <p className="border-t-2 py-4 px-6 mt-4 justify-center text-sm">
        <span>
          Made by{" "}
          <Link className="underline" to="https://camlittle.com/about">
            Cameron
          </Link>
        </span>
        <Link
          className="underline"
          to={`mailto:cameron@taostrailrunners.com?subject=TTR%20Site%20feedback&body=${encodeURIComponent(`\n\nurl: ${url}`)}`}
        >
          Found an issue?
        </Link>
        <Link className="underline" to="/privacy">
          Privacy Policy
        </Link>
      </p>
    </footer>
  );
}

function Badge({
  picture,
  name,
  email,
}: {
  picture?: string;
  name?: string;
  email: string;
}) {
  return picture ? (
    <img
      className="inline rounded-md"
      src={picture}
      alt={`${name ? `${name} ` : ""}user avatar`}
      width={20}
      height={20}
    />
  ) : (
    <div className="inline rounded-md font-bold" title={email}>
      {name ? initialize(name) : email[0] + "@"}
    </div>
  );
}

export function Page({
  children,
  user,
}: {
  user: { email: string; name?: string; picture?: string } | null;
  children: React.ReactNode;
}) {
  return (
    <div className="flex flex-col min-h-screen">
      <Header user={user} />
      {children}
      <Footer />
    </div>
  );
}
